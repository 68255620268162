import React from 'react'

function Equity() {
  return (
    <section className="hero4">
    <div className="content">
    <div className="text-background">
      <h1>Equity Partnerships</h1>
      <p>
        Our equity returns use institutional grade wealth management strategies in property cost optimization, thus minimizing risk in real estate investment, regardless of market conditions.
      </p>
    </div>
    </div>
  </section>
  )
}

export default Equity